class SwymCustomWishlist extends HTMLElement {
  constructor() {
    super();
    if (!window.SwymCallbacks) {
      window.SwymCallbacks = [];
    }
    window.SwymCallbacks.push(this.onSwymLoadCallback.bind(this));
  }

  onSwymLoadCallback(swat) {
    if (!swat) {
      console.error('Swym not initialized properly');
      return;
    }

    console.log('Swym scripts are installed on your store');

    // Wait for DOM content to be fully loaded
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => this.init(swat));
    } else {
      this.init(swat);
    }
  }

  init(swat) {
    // Core DOM elements
    const wishlistGrid = document.querySelector('[data-swym-wishlist-grid]');
    const itemTemplate = document.querySelector('[data-swym-wishlist-item-template]');
    const emptyState = document.querySelector('[data-swym-empty-state]');

    if (!wishlistGrid || !itemTemplate) {
      console.error('Required wishlist elements not found');
      return;
    }

    // Core functionality
    function renderWishlist() {
      swat.fetch(function (products) {
        if (!products?.length) {
          emptyState.classList.remove('hidden');
          return;
        }

        emptyState.classList.add('hidden');
        wishlistGrid.innerHTML = '';

        products.forEach((product) => {
          const item = itemTemplate.content.cloneNode(true);
          populateItemData(item, product);
          setupVariantSelector(item, product);
          attachItemEventListeners(item, product);
          wishlistGrid.appendChild(item);
        });
      });
    }

    function populateItemData(item, product) {
      const elements = {
        link: '[data-swym-product-link]',
        image: '[data-swym-product-image]',
        title: '[data-swym-product-title]',
        price: '[data-swym-product-price]'
      };

      Object.entries(elements).forEach(([key, selector]) => {
        const element = item.querySelector(selector);
        if (!element) return;

        if (key === 'link') element.href = product.du;
        if (key === 'image') element.src = product.iu;
        if (key === 'title') element.textContent = product.dt;
        if (key === 'price') {
          // Use Swym's built-in money format
          const formattedPrice = window.SwymOverrideMoneyFormat.replace(/{{.*}}/g, product.pr);
          element.textContent = formattedPrice;
        }
      });
    }

    function setupVariantSelector(item, product) {
      const variantDropdown = item.querySelector('[data-swym-variant-selector]');
      if (!variantDropdown || !product.du) return;

      const variantOptions = variantDropdown.querySelector('.variant-options');
      if (!variantOptions) return;

      fetch(`${product.du}.js`)
        .then((res) => res.json())
        .then((productData) => {
          // Find non-color option index (assumes color is one of the options if present)
          const nonColorOptionIndex = productData.options.findIndex((option) => option.name?.toLowerCase() !== 'color');

          variantOptions.innerHTML = '';
          productData.variants.forEach((variant) => {
            const button = document.createElement('div');
            button.classList.add('mr-2', 'flex', 'h-auto', 'w-auto', 'items-center', 'justify-center');

            const input = document.createElement('input');
            input.type = 'radio';
            input.name = `variant-${product.empi}`;
            // Use the specific option value instead of full variant title
            const optionValue = variant[`option${nonColorOptionIndex + 1}`];
            input.value = optionValue;
            input.id = `variant-${variant.id}`;
            input.classList.add('hidden', 'h-0', 'w-0', 'opacity-0', 'peer');
            if (!variant.available) {
              input.classList.add('disabled');
            }
            input.dataset.variantId = variant.id;

            const label = document.createElement('label');
            label.htmlFor = `variant-${variant.id}`;
            label.classList.add(
              'peer-[.disabled]:strike-through',
              'relative',
              'mr-3',
              'py-1',
              'text-brand-primary-100',
              'underline-offset-4',
              'hover:underline',
              'cursor-pointer',
              'peer-checked:underline',
              'peer-[.disabled]:text-brand-secondary-200',
              'peer-[.disabled]:opacity-50',
              'peer-[.disabled]:hover:no-underline',
              'peer-[.disabled]:cursor-not-allowed'
            );
            label.textContent = optionValue;

            button.appendChild(input);
            button.appendChild(label);
            variantOptions.appendChild(button);

            input.addEventListener('change', () => {
              const addToCartBtn = item.querySelector('[data-swym-add-to-cart]');
              if (addToCartBtn) {
                addToCartBtn.dataset.variantId = variant.id;
                addToCartBtn.disabled = !variant.available;
              }
            });
          });

          // Select first available variant by default
          const firstAvailable = variantOptions.querySelector('input:not(.disabled)');
          if (firstAvailable) {
            firstAvailable.checked = true;
            firstAvailable.dispatchEvent(new Event('change'));
          }
        })
        .catch((err) => console.error('Error fetching variants:', err));
    }

    function attachItemEventListeners(item, product) {
      const addToCartBtn = item.querySelector('[data-swym-add-to-cart]');
      const deleteBtn = item.querySelector('[data-swym-delete]');
      const variantSelect = item.querySelector('[data-swym-variant-selector]');

      if (addToCartBtn) {
        addToCartBtn.addEventListener('click', () => handleAddToCart(addToCartBtn, product));
      }

      if (deleteBtn) {
        deleteBtn.addEventListener('click', () => {
          swat.removeFromWishList(product, renderWishlist);
        });
      }
    }

    function handleAddToCart(button, product) {
      const variantSelector = button.closest('[data-swym-wishlist-item]').querySelector('[data-swym-variant-selector]');
      const selectedInput = variantSelector?.querySelector('input[type="radio"]:checked');

      if (!selectedInput) {
        console.error('Please select a variant');
        swat.ui.showErrorNotification({ message: 'Please select a size' });
        return;
      }

      const selectedVariant = selectedInput.dataset.variantId;
      button.textContent = 'Adding...';

      swat.replayAddToCart(
        {
          empi: product.empi,
          du: product.du
        },
        selectedVariant,
        () => {
          button.textContent = 'Added to Cart';
          updateCartDrawer();
          setTimeout(() => {
            button.textContent = 'Add to Cart';
          }, 2000);
        },
        (err) => {
          console.error('Error adding to cart:', err);
          button.textContent = 'Add to Cart';
        }
      );
    }

    function updateCartDrawer() {
      fetch('/?section_id=cart-drawer')
        .then((response) => response.text())
        .then((responseText) => {
          const cartDrawer = document.querySelector('cart-drawer');
          if (!cartDrawer) return;

          const parser = new DOMParser();
          const parsedHtml = parser.parseFromString(responseText, 'text/html');
          const drawerInner = parsedHtml.querySelector('.drawer__inner');

          cartDrawer.querySelector('.drawer__inner').innerHTML = drawerInner.innerHTML;

          // Update cart count
          const totalQuant = drawerInner.querySelector('.cart__count')?.textContent;
          if (totalQuant) {
            const cartCount = document.querySelectorAll('.cart__count');
            cartCount.forEach((element) => {
              element.innerHTML = totalQuant;
            });
          }

          cartDrawer.classList.remove('translate-x-full');
          cartDrawer.setAttribute('open', true);
          document.body.classList.add('overflow-hidden');
        });
    }

    // Initialize
    renderWishlist();
  }
}
// Register the custom element
customElements.define('swym-custom-wishlist', SwymCustomWishlist);
